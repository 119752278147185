.popup-form-control {
  background-color: whitesmoke !important;
  color: black !important;
}

.create-btn {
  color: whitesmoke !important;
}

.rti--tag {
    background-color: whitesmoke !important;
    color: black !important;
}

div[class^="react-select"] {
    background-color:  whitesmoke;
    color : black ;
    border-radius:  0px ;
}


.create-account-form {
    font-size: 14px !important;
    margin-bottom: 0px !important;
}


.button-container {
    float : right !important;
}

.reduced-margins{
    margin-bottom: 15px !important;
}