.main {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #373277;
}

.app {
   /* flex: 1 0 0%;
    max-width: 500px;
    width: 98vw;*/
    flex: 1 0 0%;
    height: auto;
    /*
    max-width: 800px;
    */
    width: auto;
    background-color: #373277;

}

pre.language-jsx {
    padding: 0 1em;
}


/*
.github-fork-ribbon {
&:before,
&:after {
     position: fixed;
 }

&:before {

 }
}
*/
.chart{
    width: 100%;
   /* //padding-top: 5%;*/
}