.ytd-chart-legend {
  cursor: pointer;
  position: relative;
  flex: 0 0 155px;

  &.activated {
    box-shadow: 0 1px 10px 1px black;
  }

  .legend-symbol {
    height: 4px;

    .symbol {
      height: 4px;
      width: 72px;
    }
  }

  .body {
    height: 31px;
  }

  .footer {
    height: 40px;
  }

  .status {
    position: absolute;
    top: 1px;
    right: 3px;
  }
}