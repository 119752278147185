#date-picker > span > div > div > span > button {
  background-color: initial !important;
  color: #fff !important;
  border: 1px solid #515757 !important;
  padding: 5px 15px !important;
  padding-bottom: 6px !important;
}

#date-picker > span > div > div > span > button:hover {
  background-color: #151616 !important;
}