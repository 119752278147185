@import "node_modules/@sg-bootstrap/core/scss/color-dark";

.overview {
  .col-md-2_4 {
    position: relative;
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }

  @media (max-width: 767px) {
    h3 {
      font-size: 1.2rem;
    }
  }

  @media (min-width: 768px) {
    .col-md-2_4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;;
    }

    .cards-holder {
      min-width: 1550px;
      min-height: 230px;
    }

    .gradient::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(149, 0, 0, 0) 0%, #303333 100%);
      height: auto;
      width: 100px;
    }
  }

  .cards {
    &.row {
      margin-left: 0;
      margin-right: 0;

      > [class*='col-'] {
        padding-inline-start: 0.5rem;
        padding-inline-end: 0.5rem;
      }

      @media (max-width: 767px) and (min-width: 576px) {
        .padding-inline-start-sm-0 {
          padding-inline-start: 0 !important;
        }

        .padding-inline-end-sm-0 {
          padding-inline-end: 0 !important;
        }
      }

      @media (min-width: 768px) {
        .padding-inline-start-md-0 {
          padding-inline-start: 0 !important;
        }

        .padding-inline-end-md-0 {
          padding-inline-end: 0 !important;
        }
      }

      @media (max-width: 575px) {

        > [class*='col-'] {
          padding-inline-start: 0;
          padding-inline-end: 0;
        }
      }
    }
  }


}