.tree-select-container {
  display: inline-block;
  min-width: 100px;
  text-align: center;
  position: relative;
  width: 100%;

  .selected-text {
    background-color: #303333;
  }

  ul {
    text-align: left;
    background-color: #303333 !important;
  }

  .select-options {
    position: absolute;
    z-index: 99999;
    box-shadow: 0 1px 20px 1px black;
    min-width: 100%;
    width: auto;
  }

  li {
    list-style-type: none;
    background: #303333;
    border-bottom: 1px solid rgba(81, 87, 87, 0.7);
    cursor: pointer;
    white-space: nowrap;

    > div {
      padding: 7px 25px 5px;
      > i {
        margin-left: -17px;
        margin-bottom: 4px;
        cursor: pointer;
        line-height: 13px;
      }
    }
  }

  .children-options {
    margin: 0;

    li:first-child {
      border-top: 1px solid rgba(81, 87, 87, 0.7);
    }

    li {
      padding: 0;
      > div {
        padding-inline-start: 40px !important;
      }
    }
  }

  li:last-child {
    border-bottom: none;
  }

  li>div:hover {
    background-color: rgba(19, 97, 255, 0.2);
  }

  .activated {
    color: #5298ff;
  }
}