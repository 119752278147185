.global-filter {
  &.sticky {
    opacity: 1;
    z-index: 3;
    background-color: #252c2d !important;
  }

  .rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
  }

  .rdtPicker td.rdtToday:before {
    right: 0 !important;
  }

  .date-filter, .scope-filter, .region-filter, .switch-filter {
    min-width: 320px;
  }

  .analysis-filter, .save-filter {
    min-width: 208px;
  }

  .analysis-filter .btn-group {
    max-width: 200px;
  }

  .form-group {
    margin-bottom: 0 !important;
  }

  .rdt {
    max-width: none !important;
  }
}

@media (min-width: 1200px) {
  .sticky {
      position: sticky;
      top: 0;
    }
}
