$button-height: 35px;
$button-width: 165px;
$list-height: 200px;
$active-button-back-color: rgba(19, 97, 255, 0.2);
.reporting {
  @media (max-width: 1600px) {
    .select-families {
      justify-content: center;
      align-items: center;
    }
  }

  .column-customization {
    overflow: hidden !important;

    .select-families {
      flex-wrap: wrap !important;
      flex-direction: row !important;
      display: flex !important;
      overflow: hidden !important;
    }

    input[type="checkbox"] {
      width: 15px;
      height: 15px;
    }

    .column-top {
      width: 100px;
      height: 35px;
      border-radius: 25px;
      background-color: #3f4243;
      text-align: center;
    }

    .column-top-checked {
      color: #5298ff !important;
      border: 1px solid #5298ff !important;
    }

    .column-children {
      padding: 0.25em 0.5em;
    }

    .column-children:focus {
      border: 1px solid rgba(81, 87, 87, 0.7);
    }

    .dropdown {
      cursor: pointer;
      width: $button-width;
      height: $button-height;
      background-color: #3f4243;
    }

    .col-dropdown {
      cursor: pointer;
      width: $button-width;
      height: $button-height;
    }

    .list-family-common {
      position: absolute;
      z-index: 1;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      height: $list-height !important;
      background-color: #303333;
      width: $button-width;
      border-bottom: 1px solid rgba(81, 87, 87, 0.7);

      dd:not(dd:last-child ) {
        border-bottom: 1px solid rgba(81, 87, 87, 0.7);
      }
    }

    .nowrap-family {
      white-space: nowrap;
    }

    .select-ul {
      label:hover {
        width: 200px;
        background-color: $active-button-back-color;
      }
    }

    dd:hover {
      background-color: $active-button-back-color;

    }

    .form-container {
      border: 0 solid transparent !important;
    }

  }

  .ag-tool-panel-wrapper {
    display: contents !important;

    .ag-tool-panel-horizontal-resize {
      display: none !important;
    }
  }

  .column, .column > :after, .tree-select-icon {
    color: #e9e9e9;
    cursor: pointer;
  }

  .column:active {
    color: #e9e9e9;
  }
}


