.react-dropdown-tree-select-create {

.tag {
  border: 1px solid #e9e9e9;
  padding: 2px 0 2px 2px;
  border-radius: 2px;
  display: inline-block
}

.tag:focus-within {
  border-color: #a0a0a0
}

.tag-remove {
  color: #a0a0a0;
  font-size: 75%;
  line-height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none
}

.tag-remove.disabled, .tag-remove.readOnly {
  cursor: not-allowed
}

.tag-remove:focus {
  color: #3c3c3c
}

.node > label {
  cursor: pointer;
  margin-left: 2px
}

.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
  width: 436px;
}

.tag-item {
  display: inline-block;
  margin: 4px
}

.tag-item .search {
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  width:200px !important;
}

.tag-item:last-child {
  margin-right: 4px
}

.node {
  list-style: none;
  white-space: nowrap;
  padding: 4px
}

.node.leaf.collapsed {
  display: none
}

.node.disabled > * {
  color: gray;
  cursor: not-allowed
}

.node.match-in-children.hide .node-label {
  opacity: .5
}

.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer
}

.toggle:after {
  content: " "
}

.toggle.collapsed:after {
  content: "+"
}

.toggle.expanded:after {
  content: "-"
}

.searchModeOn .toggle {
  display: none
}

.checkbox-item, .radio-item {
  vertical-align: middle;
  margin: 0 4px 0 0
}

.checkbox-item.simple-select, .radio-item.simple-select {
  display: none
}

.hide:not(.match-in-children) {
  display: none;
}

.react-dropdown-tree-select .dropdown {
  display: table;
  position: relative;
  width: auto;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 4px;
  line-height: 20px;
  max-height: 180px;
  display: inline-block;
  overflow: auto;
  border: 1px solid #b9b9b9;
  background-color: whitesmoke;
  color: black;
  width: 426px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  cursor: pointer;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  content: "\25BC";
  vertical-align: middle;
  color: #3c3c3c;
  float : right !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  content: "\25B2";
  vertical-align: middle;
  color: #3c3c3c;
  float : right !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
  cursor: not-allowed
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after{
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  position: absolute;
  padding: 4px;
  z-index: 1;
  border-top: 1px solid rgba(0, 0, 0, .05);
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
  box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
  background-color: whitesmoke;
  color: black;
  width: 426px;
}

.react-dropdown-tree-select .dropdown .dropdown-content .search {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}

.react-dropdown-tree-select .dropdown .dropdown-content ul {
  margin: 0;
  padding: 0
}
}