.accordion {
  .card-header .btn-link {
    color: white;
  }

  h3 {
    text-indent: -12px;
    padding-left: 20px;
  }

  @media (max-width: 767px) {
    h3 {
      font-size: 1.2rem;
    }
  }
}