.dtd-positive {
    color: white !important;
    background-color: #008000 !important;
    border-color: #008000 !important
}

.dtd-negative {
    color: white !important;
    background-color: #58060a !important;
    border-color: #58060a !important;

}

.budget-above-dtd {
    color: white !important;
    background-color: #a81a20 !important;
    border-color: #a81a20 !important;

}

.top-level-corner-numbers {
    margin-top: 2px;
}

.top-corner-numbers {
    margin-top: 7px;
}

.news-detail {
    min-height: 350px;
    max-height: 486px;
    flex-direction: column;
    overflow-y: auto;
}

.tes * > p {
    display: block !important;
}

.t-align * {
    justify-content: left !important;
    text-align: left !important;
}

.news-details {
    padding-top: 5px;
    font-size: 15px;
    line-height: 19px;
    overflow-y: auto;
    min-height: 350px;
    max-height: 486px;
    flex-direction: column;
}

p {
    padding-bottom: 5px;
}

p > font {
    display: block;
    margin-top: 5px;
    margin-bottom: -4px;
}

table {
    table-layout: fixed;
    width: 100%;
}

.title {
    color: white;
    display: inline-block;
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.sub-scopes-title {
    display: inline-block;
    max-width: 85%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.fake-bu-color{
    color: yellow !important;
}

.nav-link.inactive:hover {
    background-color: transparent !important;
}

.btn-bd-primary {

    --bs-btn-active-bg: #{shade-color($bd-violet, 20%)};
}
@media (max-width: 575px) {

    .display-4 {
        padding-left: 0px !important;
    }

}