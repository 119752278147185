.ck .ck-editor__main > .ck-editor__editable {
    background: #303333 !important;
    max-height: 400px;
}
.ck.ck-dropdown__panel {
    max-height: 400px; /* or anything else, more likely ~300px or so */
    min-height: 250px; /* or anything else, more likely ~300px or so */
    overflow-y: scroll;
}


/*body
{
    !* Font *!
    font-family: Arial, Verdana, sans-serif;
    font-size: 12px;

    !* Remove the background color to make it transparent *!
    background-color: #353c42;
    justify-content: left !important;

}*/

html
{
    /* #3658: [IE6] Editor document has horizontal scrollbar on long lines
    To prevent this misbehavior, we show the scrollbar always */
    _overflow-y: scroll
}

img:-moz-broken
{
    -moz-force-broken-image-icon : 1;
    width : 24px;
    height : 24px;
}
img, input, textarea
{
    cursor: default;
}