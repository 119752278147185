
.legend {
  list-style: none;
  padding-left: 5px !important;
  display: inline-flex;
  justify-content: space-between;
}
.legend li {
  float: left;
  margin-right: 0px;
  padding-left: 10px;
}
.legend span { float: left; width: 15px; height: 15px; margin: 3px; }

.legend .awesome { background-color: #d46200; }
.legend .superawesome { background-color: #008000; }
.legend .notawesome { background-color: #a81a20; }
.legend .notawesomeAtAll { background-color: #58060a; }