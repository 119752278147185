.account-management {
  i {
    color: whitesmoke !important;
  }

  .ag-row {
    background: rgba(35, 37, 37, 0.84) !important;
  }

  .ag-header-row, .ag-paging-panel, .ag-row-hover {
    background: rgb(25, 30, 32) !important;
  }
}