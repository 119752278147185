$red-light: #a81a20;
$red-dark: #751116;
$red-warning: #58060a;
$green-light: #008000;
$green-dark: #00602b;

$icon-large-width: 45px;
$icon-small-width: 33px;

.card {
  .bg-footer-red {
    background-color: $red-dark;
  }

  .bg-footer-green {
    background-color: $green-dark;
  }

  .bg-green {
    background-color: $green-light;
  }

  .bg-red {
    background-color: $red-light;
  }

  .bg-red-warning {
    background-color: $red-warning;
  }
  .card-header {
    min-height: 50px;
    margin-bottom: 2px;
  }

  .card-body {
    min-height: 90px;
  }

  .card-footer {
    min-height: 50px;
  }

  .icon-large-container {
    width: $icon-large-width;
  }

  .icon-small-container {
    width: $icon-small-width;
  }

  .icon-large-ms-offset {
    margin-left: -$icon-large-width;
  }

  .icon-small-ms-offset {
    margin-left: -$icon-small-width;
  }

  .icon-large {
    font-size: 2.7rem;
  }

  .icon-small {
    font-size: 1.6rem;
  }

  .no-data {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .euro {
    font-size: 20px;
  }
}