.heatmap-svg {
  .icon {
    line-height: 17px;
  }

  .orange {
    fill: #F1A943 !important;
  }

  .red {
    fill: #a81a20 !important; //same color as in Card
  }

  .dark-red {
    fill: #751116 !important; //same color as in Card
  }

  .green {
    fill: #008000 !important;
  }

  .dark-green {
    fill: #00602b !important;
  }

  .above {
    color: #008000 !important;
  }

  .below {
    color: #a81a20 !important;
  }

  .text-color-dark {
    fill: #fafafa !important;
  }

  .text-color-light {
    fill: #d8ddde !important;
  }

  .square {
    rect {
      shape-rendering: crispEdges;
      fill: #231f2066;
      stroke: #303333;
    }
  }

  .box {
    width: 20px;
    height: 20px;
    padding: 10px;
  }

  .red-box {
    background-color: #a81a20 !important;
  }

  .dark-red-box {
    background-color: #751116 !important;
  }

  .green-box {
    background-color: #008000 !important;
  }

  .dark-green-box {
    background-color: #00602b !important;
  }

  span.red {
    color: #a81a20;
  }

  span.green {
    color: #008000;
  }

  span.gray {
    color: #949c9e;
  }

  span.blue {
    color: #529aff;
  }

  .bar {
    height: 25px;
    border-block-start-width: 1px;
    color:#fff;
  }

  svg .axis path,
  .axis line {
    fill: none;
    stroke: none;
    shape-rendering: crispEdges;
  }

  svg .x.axis path {
    display: none;
  }

  .value-unit {
    margin-right: 40px;
  }

  svg {
    overflow-x: scroll !important;
  }

  .filter {
    min-width: 225px;
  }

  .filter-long {
    min-width: 270px;
  }

  .filter-short {
    min-width: 200px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 70%;
    width: 30%;
    height: 100%;
    background: #222424;
  }


  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .news-scroll {
    max-height: 80vh;
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  .extract-heatmap {
    position: absolute;
    top: 24px;
    right: 62px;
  }
}