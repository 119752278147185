.navbar {
  .navbar-brand {
    img {
      border: 1px solid #fff;
    }
  }

  .nav-link:not(.active) {
    color: #8e9697;
  }

  button:focus {
    outline: none;
  }

  .navbar-collapse {
    background-color: transparent !important;
  }

  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show, .nav-link:hover {
    color: #fff;
  }
}