.reporting-group {
  .card-body {
    position: relative;
  }

  .reporting-range-date {
    top: 110px;
    position: absolute;
    z-index: 1;
    display: none;
  }

  .ag-root-wrapper {
    border: 0 !important
  }

  .nav-tabs {
    border-bottom: none !important;

    .nav-link {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
    }

    .nav-link:hover {
      cursor: pointer;
      color: whitesmoke;
    }

    .nav-link.active {
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }

  .tabs {
    display: flex;
    justify-content: space-between;
  }

  .box-shadow {
    box-shadow: 0 1px 20px 1px black;
  }

  .dropdown-menu .icon {
    margin-top: -3px;
  }

  .pointer {
    cursor: pointer;
  }
}

.column-customisation {

  .draggable-block:hover, .isDragging {
    background-color: #949c9e;
  }

  .draggable-block .drag-control {
    display: none;
  }

  .draggable-block:hover .drag-control,
  .isDragging .drag-control {
    display: block;
    color: #777;
    float: right;
  }

  .isDragging {
    opacity: 0.5;
  }

  ul {
    border-radius: 4px;
    padding: 10px 15px;
    flex: 1 1 auto;

    li {
      display: block;
    }
  }

  ul:focus {
    outline: none;
  }
}
/*
.nav-link .nav-link.active {
  border-bottom: 3px solid #2469ff !important;
  margin-bottom: -1px;
}
*/

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #5298ff !important;

}

.nav-compact-pills .nav-link.active, .nav-compact-pills .show > .nav-link {
  border-bottom: 3px solid #5298ff !important;
  margin-bottom: -1px;
}
.message{
  color: #e60028;
  width: 500px !important;
}