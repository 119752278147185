@import '../../../routes/Admin/AccountsManagement/Variables.scss';

div[class^="roles-multi-editor"] {
    background-color:  $background-color-dark !important;
    color : $font-color-bright ;
    border-radius:  0px ;
    font-size: 10px !important;
}


div[class^="roles-multi-editor"]:focus {
    border :none ;
    box-shadow: none ;
}


.roles-multi-editor__indicators {
    display: none !important;
}






