$grid-row-padding: 21px;
$grid-row-sm-padding: 11px;
$grid-border: 1px solid #515757b3;
$grid-border-family: 3px solid rgb(81, 87, 87);

.reporting {
  .active-favorite-button, .favorite-button {
    min-width: 160px;
  }

  .ag-root {
    position: relative !important;
    top: 0;
    padding-bottom: 90px !important;
  }

  .increase {
    color: #00c74a;
  }

  .decrease {
    color: #e60028;
  }

  .brand-info {
    color: #2982ff;
  }

  .ag-header-row {
    overflow: visible;
  }

  .ag-header-icon {
    display: flex;
    align-items: center;
  }

  .group-unit-header, .indicator-header, .details-link-header {
    .ag-header-cell-label {
      align-items: flex-start;
      justify-content: center;
      padding-top: -30px;
    }

    padding-top: 0;
    border-top: none !important;

    .ag-cell-label-container {
      margin-bottom: 55px;
    }
  }

  .ag-header-row-column-group {
    height: 70px !important;
  }

  .ag-header-container {
    .ag-header-row-column:nth-child(3) {
      visibility: hidden;
    }

    .ag-header-group-cell-no-group {
      border: $grid-border;
    }
  }

  .ag-pinned-left-header, .ag-header-viewport {
    z-index: 0 !important;
    border-top: $grid-border;

  }

  .ag-pinned-left-header > div.ag-header-row-column > div.ag-header-cell:not(.group-unit-header):not(.indicator-header):not(.details-link-header) {
    visibility: hidden;
  }

  .period {
    background-color: #12468f;
  }

  .fy {
    background-color: #73187D;
    padding-top: 15px;
  }

  .quarter {
    background-color: #3D5D8A;
    padding-top: 15px;
  }
  .header-hidden {
    padding-top: 15px;
  }

  .header-versus-twice {
    padding-top: 15px;
    .ag-header-group-text{
      display: flex;
      width: max-content !important;
      white-space: nowrap !important;

    }
  }


  .ag-theme-sg-bootstrap {
    .ag-header {
      border-left: $grid-border;
      border-bottom: $grid-border;
    }
  }

  .header-versus {
    background-color: #537FBC;
    padding-top: 15px;

    .ag-header-group-text {
      display: flex;
      width: max-content !important;
      white-space: nowrap !important;

    }

  }

  .header-estimates {
    background-color: #a81a20;
  }

  .small-device {
    .ag-pinned-left-header {
      border-top: none !important;

      .group-unit-header {
        height: max-content;
      }
    }
  }

  .ag-header-group-cell-label {
    align-items: center;
    justify-content: center;
    z-index:2;
  }

  .ag-header-cell-label {
    align-items: center;
    justify-content: center;
  }

  .row-level1 {
    background: rgba(148, 156, 158, 0.05) !important;
  }

  .row-level2 {
    background: rgba(148, 156, 158, 0.1) !important;
  }

  .row-level3 {
    background: rgba(148, 156, 158, 0.15) !important;
  }

  .row-level4 {
    background: rgba(148, 156, 158, 0.2) !important;
  }

  .row-level5 {
    background: rgba(148, 156, 158, 0.25) !important;
  }

  .row-level6 {
    color: rgba(255, 255, 255, 0.73) !important;
    background: rgba(148, 156, 158, 0.3) !important;
  }

  .ag-body-viewport {
    .details-link {
      text-align: center;
    }

    .ag-column-hover:not(.unit):not(.indicator):not(.details-link), .ag-row-hover {
      background-color: rgba(19, 97, 255, 0.2) !important;
    }
  }

  .align-center {
    align-items: center;
    justify-content: center;
  }

  .ag-theme-sg-bootstrap {

    .ag-header {
      border-left: $grid-border;
      border-bottom: $grid-border;
      background-color: rgba(0, 25, 88, 0.44) !important;
    }


    .ag-header-group-cell-with-group:not(.header-hidden):not(.quarter):not(.header-versus):not(.header-versus-twice):not(.fy) {
      border-left: $grid-border-family !important;
      border-right: $grid-border-family !important;
      height: 96px;
      z-index: 1;
    }

    .ag-row {
      border: #303333 1px solid !important;;
    }

    .ag-ltr {
      .ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned,
      .ag-row.ag-cell-last-left-pinned {
        border-right: $grid-border;
      }

      .ag-row-group-leaf-indent {
        margin-left: 9px;
      }

      .ag-row-group-indent-1 {
        padding-left: $grid-row-padding;
      }

      .ag-row-group-indent-2 {
        padding-left: $grid-row-padding * 2;
      }

      .ag-row-group-indent-3 {
        padding-left: $grid-row-padding * 3;
      }

      .ag-row-group-indent-4 {
        padding-left: $grid-row-padding * 4;
      }

      .ag-row-group-indent-5 {
        padding-left: $grid-row-padding * 5;
      }

    }

    .ag-overlay-loading-wrapper {
      background-color: transparent !important;
    }

    .ag-side-bar {
      border: transparent !important;
      position: absolute !important;
      visibility: hidden;

      .ag-side-buttons {
        display: none !important;
        border: none !important;
      }
    }

    .ag-tabs-header.ag-menu-header {
      padding: 0.5rem;
      background-color: green !important;
    }
  }

  @media (max-width: 767px) {
    .ag-theme-sg-bootstrap-condensed {
      .ag-header-cell, .ag-cell {
        padding-left: 2px;
        padding-right: 4px;
      }
    }

    .ag-theme-sg-bootstrap {
      .ag-header-cell {
        border-top: $grid-border !important;
        border-right: $grid-border !important;
      }

      .ag-ltr {
        .ag-row-group-leaf-indent {
          margin-left: 4px;
        }

        .ag-row-group-indent-1 {
          padding-left: $grid-row-sm-padding;
        }

        .ag-row-group-indent-2 {
          padding-left: $grid-row-sm-padding * 2;
        }

        .ag-row-group-indent-3 {
          padding-left: $grid-row-sm-padding * 3;
        }

        .ag-row-group-indent-4 {
          padding-left: $grid-row-sm-padding * 4;
        }

        .ag-row-group-indent-5 {
          padding-left: $grid-row-sm-padding * 5;
        }
      }
    }
  }

  .settings {
    z-index: 10;
    margin-top: -4em;

    button {
      line-height: 1.5rem;
      font-weight: 500;
    }
  }

  .favorite-button.active, .active-favorite-button {
    color: #5298ff !important;
  }

  .favorite-button.active::after {
    visibility: hidden !important
  }

  // center family name - header 1
  .ag-header-viewport > div > div:nth-child(1) > div > div.ag-header-group-cell-label > span.ag-header-group-text {
    margin-top: -4.7em;
  }

  // center column name - header 2
  .ag-header-group-cell-with-group > .ag-header-group-cell-label > .ag-header-group-text {
    margin-top: -1.70em;
  }

  .ag-ltr .ag-header-cell-resize {
    height: 213px;
    right: -4px;
    top: -74px;
  }

  .ag-overlay-loading-wrapper {
    margin-top: 30px !important;
  }

  .ag-header-group-cell {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.ag-popup-child {
  z-index: 5;
  background-color: #303333;
  box-shadow: 0 1px 20px 1px black;
  padding: 0.5rem;
  margin-top: 0.5rem;
}
