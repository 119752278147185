.mini-heatmap-svg {
  .icon {
    line-height: 17px;
  }

  .orange {
    fill: #F1A943 !important;
  }

  .red {
    fill: #a81a20 !important; //same color as in Card
  }

  .green {
    fill: #008000 !important;
  }

  .above {
    color: #008000 !important;
  }

  .below {
    color: #a81a20 !important;
  }

  .text-color-dark {
    fill: #fafafa !important;
  }

  .text-color-light {
    fill: #d8ddde !important;
  }

  .square {
    rect {
      shape-rendering: crispEdges;
      fill: #464b4b;
      stroke: #303333;
    }
  }
}

