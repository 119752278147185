.align-items-left {
    text-align: left !important;
}

.indicator-amount{
    display: flex !important;
    flex-direction: column !important;
    width: max-content !important;
}

.card-body {
    margin-bottom: 2px !important;
}

.footer-variation{
  text-align: center !important;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-bottom: 2px !important;
  padding-top: 0.3rem;
  width: 100% !important;
}

#footer-card-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
  margin-left: -1rem !important;
  margin-right: -1rem !important;
  width: calc(100% + 2rem) !important;
}

#header-card-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
  margin-left: -1rem !important;
  margin-right: -1rem !important;
  width: calc(100% + 2rem) !important;
}


.footer-align-items-left {
    text-align: left !important;
  /*//padding-left:  0.5rem !important;*/
    margin-bottom: 2px !important;
}

.variation-text{
    margin-bottom: 0.1rem !important;
}

.hint-item{
    height: 20px!important;
}

.d-minus-mln-label {
  font-size: 15px !important;
  font-weight: bold !important;
  width: max-content !important;
}


.restricted-body-height{
   max-height : 50px !important;
}

.custom-warning{
    background-color: #d46200 !important;
}